<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Banco Central - Logs</h4>
            <div class="small text-muted">Detalle de cada consulta realizada al Banco Central</div>
          </b-col>    
          <b-col sm="5">
            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>
          </b-col>             
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row v-if="table.mostrarFiltros">
          <b-col sm="4">
            <b-form-group>
              <b-input-group>
                <b-form-input type="text" size="sm" placeholder="Ingrese su busqueda" v-model="table.filter"></b-form-input>
                <b-input-group-prepend>
                  <b-button variant="dark" size="sm" :disabled="!table.filter" @click="table.filter = ''">
                    <i class="fa fa-filter"></i> Filtrar
                  </b-button>
                </b-input-group-prepend>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-table class="mb-0"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="table.items"
                    :fields="table.fields"
                    :filter="table.filter"
                    :current-page="table.currentPage"
                    :per-page="table.perPage"
                    v-if="table.items.length">    
                
                <template v-slot:cell(created_at)="data">
                  <div v-if="moment(data.item.created_at).format('D/M/Y')==moment().format('D/M/Y')" class="text-primary">
                    <b>{{moment(data.item.created_at).format('D/M/Y - LT')}}</b>
                  </div>
                  <div v-else>
                    {{moment(data.item.created_at).format('D/M/Y - LT')}}
                  </div>
                </template>

                <template v-slot:cell(search)="data">
                  <a @click="irReporte(data.item)" class="bcra-log-link-busqueda">
                    <div v-if="data.item.documento"> 
                      <b>DNI:</b> {{data.item.documento}}
                    </div>
                    <div v-else>
                      <b>CUIT / CUIL:</b> {{data.item.cuil}}
                    </div>
                  </a>
                </template>

                <template v-slot:cell(info)="data">
                  <div v-if="data.item.documento"> 
                    <a class="bcra-log-query-link-cuitonlinea" @click="verInfoCuit(data.item.documento)">
                      Conocer mas sobre este DNI
                    </a>
                  </div>
                  <div v-else>
                    <a class="bcra-log-query-link-cuitonlinea" @click="verInfoCuit(data.item.cuil)">
                      Conocer mas sobre este CUIL / CUIT
                    </a>
                  </div>
                </template>

                <template v-slot:cell(f_action)="data">
                  <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                    <b-dropdown-item @click="irReporte(data.item)">
                      <b-icon icon="file-earmark-spreadsheet"></b-icon> Reporte
                    </b-dropdown-item>                         
                  </b-dropdown>
                </template>                
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>

          <b-col>
            <nav>
              <b-pagination class="pull-right mb-0"
                            size="sm"
                            pills=""
                            :total-rows="getRowCount(table.items)"
                            :per-page="table.perPage"
                            v-model="table.currentPage" />
            </nav>
          </b-col>          
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 

  export default {
    data: () => {
      return {      
        access: {
          module_id: Modules.BCRA,
          profile_id: Profiles.PERSONAL,
          view_reference: 'logs',
          elements: {}
        }, 
        table : {
          items: [],
          fields: [
            {key: 'created_at', label: 'Consulta', sortable: true},            
            {key: 'search', label: 'Búsqueda'},
            {key: 'info', label: 'Más Info'},
            {key: 'f_action', label:''},
          ],
          filter: null,
          mostrarFiltros: false,
          currentPage: 1,
          perPage: 20,
        },       
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.load()
    },
    methods: {
      getRowCount (items) {
        return items.length
      },
      load() {
        var result = serviceAPI.obtenerBCRALog()

        result.then((response) => {
          var data = response.data
          this.table.items = data
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });        
      },
      verInfoCuit(cuil) {        
        window.open('https://www.cuitonline.com/search.php?q=' + cuil, '_blank')        
      },       
      irReporte(data) {
        this.$router.push({ name: 'BCRAStaffQueryParams', params: { params: data.response } })
      }
    }    
  }
</script>
<style>
  .bcra-log-query-link-cuitonlinea {
    color: red !important;
    font-weight: 700 !important;
    text-decoration: underline !important;
    cursor: pointer !important;
  }
  .bcra-log-link-busqueda {
    cursor: pointer;
    text-decoration: underline !important;
  }
</style>